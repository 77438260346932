<template>
  <div class="category-full">
    <div class="name">
      <h3>{{ category }}</h3>
    </div>

    <!-- List -->
    <div class="list">
      <b-link
        v-for="(item, i) in list" :key="i"
        class="thumbnail-wrapper"
      >
        <!-- Image -->
        <slot v-if="item.preview_file" >
          <div v-if="hoverStates[i]" class="hover-text">{{ item.name }}</div>
          <b-link  @click="openModal(item.id)" @mouseover="setHovered(i, true)" @mouseout="setHovered(i, false)">
            <thumbnail-image :image="item.preview_file"></thumbnail-image>
          </b-link>
        </slot>
      </b-link>
    </div>
      <!--File Record -->
      <file-group-record v-if="modal" :fileGroupRecordId="selectedFileGroupRecordId" @closeModal="closeModal"></file-group-record>
  </div>
</template>

<style lang="scss" scoped>
@import "./CategoryFull";

</style>

<script>

export default {
  props: {
    category: String,
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedFileGroupRecordId: false,
      modal: false,
      hoverStates: new Array(this.list.length).fill(false),
    };
  },

  components: {
    FileGroupRecord: () => import('@/components/Modals/FileGroupRecord/FileGroupRecord.vue'),
    ThumbnailImage: () => import('@/components/Thumbnails/Image.vue'),
  },

  methods: {
    openModal(FileGroupRecordId) {
      this.modal = true;
      this.selectedFileGroupRecordId = FileGroupRecordId;
      setTimeout(() => {
        this.$bvModal.show('file-group-record');
      }, 50);
    },
    closeModal() {
      this.modal = false;
      this.$bvModal.hide('file-group-record');
    },
    setHovered(index, value) {
      this.$set(this.hoverStates, index, value);
    },
  },
};
</script>
